import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import {useForm, Controller} from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import * as firebase from 'firebase/app';
import 'firebase/database';

import logo from '../logo.png'

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyCPQ2LlNEcxeHTOxYBigUSsIO1JcB0hPPw",
        authDomain: "korzan-dev-services.firebaseapp.com",
        databaseURL: "https://korzan-dev-services.firebaseio.com",
        projectId: "korzan-dev-services",
        storageBucket: "korzan-dev-services.appspot.com",
        messagingSenderId: "441801703437",
        appId: "1:441801703437:web:905e995b15ae0da30cd544",
        measurementId: "G-67Q1BT4955"
    });
}
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginTop: 50,
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    question: {
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        marginBottom: 50,
        border: '1px solid #000',
        borderRadius: 5,
        padding: 10
    },
    hidden: {
        display: 'none'
    },
    reqInfo: {
        marginTop: 15,
        fontSize: 12,
    },
    logo: {
        height: 110,
    }
}));
const Form = ({match}) => {
    const classes = useStyles();

    const id = match.params.id
    const formID = match.params.formID
    const db = firebase.database()

    const [dbLoaded, setDbLoaded] = useState(false)
    const [dbErr, setDbErr] = useState(false)
    const [pattern, setPattern] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const [token, setToken] = useState(null)
    const getDB = () => {
        db.ref(`forms/patterns/${id}/${formID}`).once('value').then((snapshot) => {
            if(snapshot.val()){
                if(snapshot.val().enable !== true){
                    setDbErr(true)
                    window.alert("This form is disabled.")
                }else{
                    setDbErr(false)
                    setPattern(snapshot.val())
                    document.title = `${snapshot.val().title} - AppForm` 
                }

            }else{
                setDbErr(true)
            }
            setDbLoaded(true);
        }).catch(error => {
            console.log(error)
        });
    }
    const sendToDB = (data) => {
        setDbLoaded(false)
        const newFormRef = db.ref(`forms/responses/${id}/${formID}`).push();
        newFormRef.set(data)
        .then(() => {
            console.log("success create")
            if(pattern.notify === true){
                const url = "/appform-notify.php";
                const bodyFormData = new FormData();
                bodyFormData.append('form', pattern.title);
                bodyFormData.append('to', pattern.user);
                bodyFormData.append('key', "AN2C");
                bodyFormData.append('recaptcha_response', token)
                window.fetch(url, {
                    method: "POST",
                    body: bodyFormData
                })
                .then(data => data.json())
                .then(res => {
                    if(res.success !== true){
                        console.log("notify error")
                        window.alert("notify error")
                    }
                })
                .catch(() => {
                    console.log("notify server error")
                });
            }

            setDbLoaded(true)
            setIsSuccess(true)
        }).catch(() => {
            console.log("error create")
        })
    }
    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6Le3X_YZAAAAAPcSR8C0NHlDiK7dGuZz1bkbMxdf', { action: 'contact' }).then((token) => {
                setToken(token);
            });
        });
    }
    useEffect(() => {
        getDB();
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6Le3X_YZAAAAAPcSR8C0NHlDiK7dGuZz1bkbMxdf';
        script.addEventListener('load', handleLoaded);
        document.body.appendChild(script);
    }, [])

    const { register, control, handleSubmit } = useForm();

    const onSubmit = values => {
        const data = JSON.parse(JSON.stringify(values))
        sendToDB(data)
    }
    return(
        <div className={classes.root}>
            {!dbLoaded &&
                <CircularProgress />
            }
            {dbErr &&
                <div>
                    <Typography variant='h6'>
                        Form not found!
                    </Typography>
                </div>
            }
            {!dbErr && dbLoaded ? (
                <div>
                    {!isSuccess ? (
                        <>
                            <Typography variant='h4'>
                                Form: {pattern.title}
                            </Typography>
                            <Typography variant='h6'>
                                {pattern.description}
                            </Typography> 
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {pattern.question.map((item, i) => 
                                        <div key={i} className={classes.question}>
                                            <Typography variant='h6'>
                                                {item.content}
                                                <br></br>
                                                <input className={classes.hidden} ref={register} type='text' name={`question.answers[${i}].qType`} defaultValue={item.qType}/>
                                                <input className={classes.hidden} ref={register} type='text' name={`question.answers[${i}].title`} defaultValue={item.content}/>

                                                {item.qType === "text" &&
                                                    <div>
                                                        <Input fullWidth name={`question.answers[${i}].content`} inputRef={register({required: item.req, minLength: 1, pattern: new RegExp(item.regex, "g")})} placeholder={`type here`}/>
                                                    </div>
                                                }

                                                {item.qType === "radio" &&
                                                    <div>
                                                        <Controller
                                                            rules={{required: item.req}}
                                                            as={
                                                                <RadioGroup>
                                                                    {item.answers.map((node, n) =>
                                                                        <FormControlLabel
                                                                            key={n}
                                                                            value={node.content}
                                                                            control={<Radio />}
                                                                            label={node.content}
                                                                        />
                                                                    )}
                                                                </RadioGroup>
                                                            }
                                                            name={`question.answers[${i}].content`}
                                                            control={control}
                                                        />
                                                    </div>
                                                }

                                                {item.qType === "checkbox" &&
                                                    <div>
                                                        {item.answers.map((node, n) =>
                                                            <div key={n}>
                                                                <Controller
                                                                    control={control}
                                                                    name={`question.answers[${i}].content.${n}`}
                                                                    render={({ onChange, value, ref }) => (
                                                                        <FormControlLabel
                                                                            control={<Checkbox onChange={e => onChange(e.target.checked && node.content)} checked={value} inputRef={ref}/>}
                                                                            label={node.content}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                }

                                                {item.qType === "date" &&
                                                    <div>
                                                        <Input name={`question.answers[${i}].content`} inputRef={register({required: item.req})} type="date" label="select date"/>
                                                    </div>
                                                }
                                                {item.req &&
                                                    <Typography className={classes.reqInfo}>*required</Typography>
                                                }
                                                {item.qType === "text" && item.regex.length > 0 ? (
                                                    <Typography className={classes.reqInfo}>*custom pattern</Typography>
                                                ): null}
                                            </Typography>
                                        </div>
                                    )}
                                    <input className={classes.hidden} ref={register} type='text' name={`question.time`} defaultValue={new Date().toISOString().split('T')[0]}/>
                                </div>
                                <div>
                                    <Button style={{marginBottom: 30}} color="primary" variant="contained" type="submit">Send</Button>
                                    <Typography>Powered by Leszekk.eu</Typography>
                                    <Typography>&copy; Korzan Dev Enterprise</Typography>
                                    <img src={logo} className={classes.logo} alt='logo'/>
                                    <br></br>
                                    <Typography style={{fontSize: 11}}>
                                        This site is protected by reCAPTCHA and the Google
                                        <a target="_blank" href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                        <a target="_blank" href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                    </Typography>

                                </div>
                            </form>
                        </>
                    ) : (
                        <div>
                            <Typography variant="h4">Sent!</Typography>
                            <br></br>
                            <Typography>message:</Typography>
                            <br></br>
                            <Typography variant="h5">{pattern.msg}</Typography>
                            <div>
                                <br></br>
                                <br></br>
                                <Typography>AppForm</Typography>
                                <Typography>Powered by Leszekk.eu</Typography>
                            </div>
                        </div>
                    )}
                </div>
            ) : (null)}
        </div>
    )
}
export default withRouter(Form);