import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ShareIcon from '@material-ui/icons/Share';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Input from '@material-ui/core/Input';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import copy from 'copy-to-clipboard';

import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyCPQ2LlNEcxeHTOxYBigUSsIO1JcB0hPPw",
        authDomain: "korzan-dev-services.firebaseapp.com",
        databaseURL: "https://korzan-dev-services.firebaseio.com",
        projectId: "korzan-dev-services",
        storageBucket: "korzan-dev-services.appspot.com",
        messagingSenderId: "441801703437",
        appId: "1:441801703437:web:905e995b15ae0da30cd544",
        measurementId: "G-67Q1BT4955"
    });
}
const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        marginTop: '50px'
    },
    loginBtn: {
        marginTop: '20px'
    },
    error: {
        margin: 10
    },
    option: {
        margin: 10
    },
    fields: {
        marginTop: 50
    },
    question: {
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        marginBottom: 50,
        border: '1px solid #000',
        borderRadius: 5,
        padding: 10
    },
    questionBtn: {
        margin: 3
    },
    card: {
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 10,
        marginBottom: 10
    },
    center: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    answer: {
        maxWidth: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        marginBottom: 50,
        border: '1px solid #000',
        borderRadius: 5,
        padding: 10
    },
    deleteAnswerBtn: {
        position: 'relative',
        top: -2
    },
    answerTop: {
        textAlign: 'right'
    },
    hidden: {
        display: 'none'
    },
    tableAllData: {
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}));
const RadioElms = ({register, index, control}) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `question[${index}].answers`
    });
    return(
        <div>
            {fields.map((item, k) => 
                <div key={item.id}>
                    <Input inputRef={register({required: true, minLength: 1})} name={`question[${index}].answers[${k}].content`} placeholder={`answer ${k+1}`} defaultValue={item.content}/>
                    <IconButton onClick={() => remove(k)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )}
            <br></br>
            <Button onClick={() => append({content: ""})} variant="outlined">Add</Button>
        </div>
    )
}
const Dashboard = () => {
    const classes = useStyles();
    
    const [isLogged, setIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [userData, setUserData] = useState('');

    const [DbLoaded, setDbLoaded] = useState(false);
    const [formsData, setFormsData] = useState('');
    const [clearData, setClearData] = useState('')
    const [currentId, setCurrentId] = useState('');

    const [isOpenEditor, setIsOpenEditor] = useState(false);
    const [isShowAnswers, setIsShowAnswers] = useState(false);

    const [answersData, setAnswersData] = useState('');

    const [showTable, setShowTable] = useState(false)

    const [chartData, setChartData] = useState(null)
    
    const options = {
        tooltips: {
            enabled: true
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(2)+"%";
                    return `${percentage} - ${value}`;
                },
                color: '#fff',
            }
        }
    };
    const auth = () => {
        setIsLoading(true);
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setIsLogged(true);
                setUserData(user);
                getFormsListDB(user);
            } else {
                setIsLogged(false);
                setUserData('');
            }
            setIsLoading(false);
        })
    }
    const login = () => {
        setError('');
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(() => {
            auth();
        }).catch(error => {
            if(error !== "auth/cancelled-popup-request"){
                setError(error.message);
            }
        })
    }
    const signOut = () => {
        setDbLoaded(false);
        setFormsData('');
        setIsOpenEditor(false);
        firebase.auth().signOut();
    }
    useEffect(() => {
        document.title = 'Dashboard - AppForm';
        auth();
    }, [])
    const db = firebase.database()
    const getFormsListDB= (user) => {
        db.ref('forms/patterns/' + user.uid).once('value').then((snapshot) => {
            if(snapshot.val()){
                setFormsData(Object.entries(snapshot.val()))
                setClearData(snapshot.val())
            }
            setDbLoaded(true);
        }).catch(error => {
            console.log(error)
        });
    }
    const updateForm = (data) => {
        if(!data.question){
            return window.alert("There are no questions!")
        }
        if(currentId !== '' && currentId.length > 3){
                db.ref(`forms/patterns/${userData.uid}/${currentId}`).set(data)
                .then(() => {
                    console.log("success update")
                }).catch(() => {
                    console.log("error update")
                })
        }else{
            const newFormRef = db.ref('forms/patterns/' + userData.uid).push();
            newFormRef.set(data)
            .then(() => {
                console.log("success create")
            }).catch(() => {
                console.log("error create")
            })
        }
        window.location.reload()
    }

    const { register, watch, control, handleSubmit, reset } = useForm();

    const { fields, append, remove, swap } = useFieldArray(
        {
          control,
          name: "question"
        }
    );

    const createForm = (index) => {
        setIsOpenEditor(true);
        if(index !== undefined){
            reset(formsData[index][1])
            setCurrentId(formsData[index][0])
        }

    }
    const removeForm = (name) => {
        if(window.confirm('Are you sure you want to delete this form?')){
            const dataToUpload = clearData
            delete dataToUpload[name]
            db.ref('forms/patterns/' + userData.uid).set(dataToUpload)
            .then(() => {
                console.log("success delete")
                db.ref(`forms/responses/${userData.uid}/${name}`).set({})
                window.location.reload()
            })
            .catch(() => {
                console.log("error delete")
            })
        }
    }
    const deleteAnswer = (name) => {
        if(window.confirm('Are you sure you want to delete this answer?')){
            db.ref(`forms/responses/${userData.uid}/${currentId}/${name}`).set({})
            .then(() => {
                console.log("success delete")
                window.alert("Delete success!")
                window.location.reload()
            })
            .catch(() => {
                console.log("error delete")
            })
        }
    }
    const showAnswers = (name) => {
        setCurrentId(name)
        db.ref(`forms/responses/${userData.uid}/${name}`).once('value').then((snapshot) => {
            if(snapshot.val()){
                setAnswersData(Object.entries(snapshot.val()))
                setIsShowAnswers(true)
            }else{
                window.alert("no answers")
            }
        }).catch(error => {
            console.log(error)
        });
    }
    const share = (name) => {
        const toCopy = `https://form.leszekk.eu/form/${userData.uid}/${name}`
        copy(toCopy)
        window.alert(`Copied! \n ${toCopy}`)
    }

    const onSubmit = (values) => {
        const data = JSON.parse(JSON.stringify(values))
        updateForm(data)
    }
    const showChart = (index) => {
        const labels = []
        const data = []
        answersData.map((item, i) => {
            if(typeof(item[1].question.answers[index]) == 'undefined'){
                return
            }
            if(labels.includes(item[1].question.answers[index].content)){
                data[i-1] = data[i-1] + 1
            }else{
                labels.push(item[1].question.answers[index].content)
                data.push(1) 
            }

        })
        const chartDataObj = {
            labels: labels,
            datasets: [
                {
                    label: '#',
                    data: data,
                    backgroundColor: [
                        '#2196f3',
                        '#ffeb3b',
                        '#f44336',
                        '#64dd17',
                        '#ff5722',
                        '#42a5f5'
                    ],
                },
                
            ],
            
        }
        setChartData(chartDataObj)
    }
    return(
        <div className={classes.root}>
            {!isLogged && isLoading ? (
                <CircularProgress />
            ) : (null)}

            {!isLogged && !isLoading ? (
                <div>
                    <Typography className={classes.title} variant="h4">Login to Mange Your Forms</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.loginBtn}
                        onClick={() => login()}
                    >
                        Login with Google
                    </Button>
                    <Typography className={classes.error}>{error}</Typography>
                </div>
            ) : (null)}

            {!isLoading && isLogged && userData ?(
                <div>
                    {isShowAnswers ? (
                        <>
                            <Button style={{marginBottom: 10}} color="primary" variant="outlined" onClick={() => window.location.reload()}>Back</Button>
                            <br></br>
                            <Button color="primary" variant="outlined" onClick={() => setShowTable(true)}>all data (beta)</Button>

                            {showTable &&
                                <>
                                    <TableContainer className={classes.tableAllData} component={Paper}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                        {answersData[answersData.length - 1][1].question.answers.map((node, n) =>
                                                            <TableCell key={n}>
                                                                <b>{node.title}</b>
                                                                {node.qType === "radio" &&
                                                                    <Button onClick={()=> showChart(n)}>Show chart</Button>
                                                                }
                                                            </TableCell>
                                                        )}
                                                </TableRow>
                                            </TableHead>
                                            {answersData.map((item, i) =>
                                                <TableBody key={i}>
                                                    {item[1].question.answers.map((node, n) =>
                                                        <TableCell key={n}>
                                                            {node.qType === "checkbox" ? (
                                                                <>
                                                                    {node.content && Object.values(node.content).filter(Boolean).join()}
                                                                </>
                                                            ): (
                                                                <>
                                                                    {node.content}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    )}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                    <Pie data={chartData} options={options} />
                                </>
                            }

                            {answersData.map((item, i) =>
                                <div className={classes.answer} key={i}>
                                    <div className={classes.answerTop}>
                                        {item[1].question.time}
                                        <IconButton className={classes.deleteAnswerBtn} onClick={() => deleteAnswer(item[0])}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>

                                    <hr></hr>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><b>Question</b></TableCell>
                                                    <TableCell><b>Answer</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item[1].question.answers.map((node, n) =>
                                                    <TableRow key={n}>
                                                        <TableCell style={{maxWidth: 80}} component="th" scope="row">
                                                            <i>[{node.qType}] -</i>
                                                            {' '}
                                                            {node.title}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {node.qType === "checkbox" ? (
                                                                <>
                                                                    {node.content && Object.values(node.content).filter(Boolean).join()}
                                                                </>
                                                            ): (
                                                                <>
                                                                    {node.content}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {!isOpenEditor ? (
                                <div>
                                    <div>
                                        <Button className={classes.option} color="primary" variant="contained" onClick={() => {signOut()}}>logout</Button>
                                        <Button className={classes.option} color="primary" variant="contained" disabled={!DbLoaded} onClick={() => {createForm()}}>Create Form</Button>
                                    </div>
                                    <div>
                                        {DbLoaded && formsData.length > 0 ? (
                                            <>
                                                {formsData.map((item, index) => 
                                                    <Card variant="outlined" key={item[0]} className={classes.card}>
                                                        <CardContent>
                                                            <Typography variant="h5" component="h2">
                                                                {item[1].title}
                                                                <IconButton onClick={() => share(item[0])}>
                                                                    <ShareIcon/>
                                                                </IconButton>
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions className={classes.center}>
                                                            <Button variant="outlined" onClick={() => showAnswers(item[0])}>answers</Button>
                                                            <Button variant="outlined" onClick={() => createForm(index)}>edit</Button>
                                                            <Button variant="outlined" onClick={() => removeForm(item[0])}>remove</Button>
                                                        </CardActions>
                                                    </Card>
                                                )}
                                            </>
                                        ) : (null)}

                                        {DbLoaded && formsData.length < 1 ? (
                                            <div>You don't have any forms.</div>
                                        ) : (null)}
                                    </div>
                                </div> 
                            ) : (
                                <div>
                                    <Button color="primary" variant="outlined" onClick={() => window.location.reload()}>Back</Button>
                                    <Button className={classes.option} color="primary" variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => {append({content: ''})}}>Add Question</Button>
                                    <div className={classes.fields}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Input inputRef={register({required: true, minLength: 1})} name='title' placeholder='title'/>
                                            <br></br>
                                            <Input style={{margin:10}} inputRef={register({maxLength: 150})} name='description' placeholder='description'/>
                                            <br></br>
                                            <Input inputRef={register({maxLength: 200})} name='msg' placeholder='success message'/>
                                            <br></br>
                                            <input className={classes.hidden} ref={register} type='text' name='user' defaultValue={userData.email}/>
                                            <Controller
                                                control={control}
                                                name={`enable`}
                                                render={({ onChange, value, ref }) => (
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={e => onChange(e.target.checked ? true : false)} checked={value} inputRef={ref}/>}
                                                        label="enable form"
                                                    />
                                                )}
                                            />
                                            <br></br>
                                            <Controller
                                                control={control}
                                                name={'notify'}
                                                render={({ onChange, value, ref }) => (
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={e => onChange(e.target.checked ? true : false)} checked={value} inputRef={ref}/>}
                                                        label="notify me"
                                                    />
                                                )}
                                            />
                                            {fields.map((question, q) => {
                                                return (
                                                    <div className={classes.question} key={question.id}>
                                                        <Input fullWidth name={`question[${q}].content`} inputRef={register({required: true, minLength: 1})} placeholder={`Question ${q+1}`} defaultValue={question.content}/>
                                                        <Button onClick={() => remove(q)} className={classes.questionBtn} variant="outlined">Remove</Button>
                                                        <Button className={classes.questionBtn} onClick={()=> q>0 && swap(q,q-1)} variant="outlined" startIcon={<ExpandLessIcon/>}>Up</Button>
                                                        <Button className={classes.questionBtn} onClick={()=> q !== fields.length - 1 && swap(q,q+1)} variant="outlined" startIcon={<ExpandMoreIcon/>}>Down</Button>
                                                        <div>
                                                            <select defaultValue={question.qType} name={`question[${q}].qType`} id="qType" ref={register({required: true})}>
                                                                <option value="text">Text</option>
                                                                <option value="radio">One answer</option>
                                                                <option value="checkbox">Multiple answer</option>
                                                                <option value="date">Date picker</option>
                                                            </select>
                                                        </div>
                                                        {watch(`question[${q}].qType`) === "radio" && 
                                                            <>
                                                                <RadioElms register={register} index={q} control={control}/>
                                                            </>
                                                        }
                                                        {watch(`question[${q}].qType`) === "checkbox" && 
                                                            <>
                                                                <RadioElms register={register} index={q} control={control}/>
                                                            </>
                                                        }
                                                        
                                                        {watch(`question[${q}].qType`) === "text" &&
                                                            <>
                                                                <p>text answer type</p>
                                                                <Input inputRef={register} name={`question[${q}].regex`} placeholder="regex" defaultValue={question.regex}/>
                                                                <br></br>
                                                            </>
                                                        }
                                                        {watch(`question[${q}].qType`) === "date" &&
                                                            <>
                                                                <p>date picker type</p>
                                                            </>
                                                        }
                                                        {watch(`question[${q}].qType`) !== "checkbox" &&
                                                            <Controller
                                                                control={control}
                                                                name={`question[${q}].req`}
                                                                render={({ onChange, value, ref }) => (
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={e => onChange(e.target.checked ? true : false)} checked={value} inputRef={ref}/>}
                                                                        label="required"
                                                                    />
                                                                )}
                                                            />
                                                        }
                               
                                                    </div>
                                                );
                                            })}
                                            <div>
                                                <br></br>
                                                <Button style={{marginBottom: 10}} color="primary" variant="contained" type="submit">Update</Button>
                                                <br></br>
                                                <Typography>
                                                    Your email will be shared.
                                                </Typography>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            )}
                        </>
                    )}

                </div>
            ) : (null)}
        </div>
    )
}
export default Dashboard;