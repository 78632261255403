import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Form from '../pages/Form';
import Menu from '../components/Menu';
const RenderPage = () => {
    return(
        <Router>
            <Menu/>
            <Switch>
                <Route path="/form/:id/:formID">
                    <Form/>
                </Route>
                <Route path="/dashboard">
                    <Dashboard/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
        </Router>
    )
}
export default RenderPage;